import * as React from "react";
const SVGComponent = (props) => (
	<svg
		width={25}
		height={24}
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M4.0221 14.6604L2.50211 13.1404C1.88211 12.5204 1.88211 11.5004 2.50211 10.8804L4.0221 9.36039C4.2821 9.10039 4.4921 8.59038 4.4921 8.23038V6.08036C4.4921 5.20036 5.21211 4.48038 6.09211 4.48038H8.2421C8.6021 4.48038 9.1121 4.27041 9.3721 4.01041L10.8921 2.49039C11.5121 1.87039 12.5321 1.87039 13.1521 2.49039L14.6721 4.01041C14.9321 4.27041 15.4421 4.48038 15.8021 4.48038H17.9521C18.8321 4.48038 19.5521 5.20036 19.5521 6.08036V8.23038C19.5521 8.59038 19.7621 9.10039 20.0221 9.36039L21.5421 10.8804C22.1621 11.5004 22.1621 12.5204 21.5421 13.1404L20.0221 14.6604C19.7621 14.9204 19.5521 15.4304 19.5521 15.7904V17.9403C19.5521 18.8203 18.8321 19.5404 17.9521 19.5404H15.8021C15.4421 19.5404 14.9321 19.7504 14.6721 20.0104L13.1521 21.5304C12.5321 22.1504 11.5121 22.1504 10.8921 21.5304L9.3721 20.0104C9.1121 19.7504 8.6021 19.5404 8.2421 19.5404H6.09211C5.21211 19.5404 4.4921 18.8203 4.4921 17.9403V15.7904C4.4921 15.4204 4.2821 14.9104 4.0221 14.6604Z"
			stroke="#171717"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.0332 15L15.0332 9"
			stroke="#171717"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.5277 14.5H14.5367"
			stroke="#171717"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.52771 9.5H9.53669"
			stroke="#171717"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default SVGComponent;
