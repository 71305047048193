import React, {useState, useEffect} from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MUI from "./theme/MUI";

const AppWrapper = () => {
	const storedThemeMode = localStorage.getItem("themeMode") || "light";
	const [themeMode, setThemeMode] = useState(storedThemeMode);

	useEffect(() => {
		localStorage.setItem("themeMode", themeMode);
	}, [themeMode]);

	const toggleThemeMode = () => {
		setThemeMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
	};

	const theme = MUI(themeMode);

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<App toggleThemeMode={toggleThemeMode} />
		</ThemeProvider>
	);
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<AppWrapper />
	</React.StrictMode>
);

reportWebVitals();
