// import React, {useState} from "react";
// import "./TextInput.css";
// import {Typography} from "@mui/material";
// import {VscEye, VscEyeClosed} from "react-icons/vsc";

// const FloatingLabelInput = ({
// 	handleChange,
// 	value,
// 	Placeholder,
// 	name,
// 	validate,
// }) => {
// 	const [isFocused, setIsFocused] = useState(false);
// 	const [showPassword, setShowPassword] = useState(false);

// 	const handleFocus = () => setIsFocused(true);
// 	const handleBlur = () => setIsFocused(value !== "");
// 	const togglePasswordVisibility = () => setShowPassword(!showPassword);

// 	return (
// 		<div className="floating-label-input">
// 			<button
// 				type="button"
// 				className="showPassword"
// 				onClick={togglePasswordVisibility}
// 				style={{
// 					background: "none",
// 					border: "none",
// 					cursor: "pointer",
// 					position: "absolute",
// 					right: "10px", // Adjust based on your input styling
// 					top: "50%", // Adjust based on your input styling
// 					transform: "translateY(-50%)",
// 					padding: "0",
// 				}}
// 			>
// 				{showPassword ? <VscEyeClosed size={24} /> : <VscEye size={24} />}
// 			</button>
// 			<label className={isFocused || value ? "label-focused" : ""}>
// 				{Placeholder}
// 			</label>
// 			<input
// 				type={showPassword ? "text" : "password"} // Toggle between text and password
// 				name={name}
// 				value={value}
// 				onFocus={handleFocus}
// 				onBlur={handleBlur}
// 				onChange={handleChange}
// 				autoComplete="off" // Disable autofill/autocomplete
// 			/>
// 			<Typography
// 				sx={{
// 					color: "red",
// 					fontSize: "12px",
// 					paddingLeft: "12px",
// 					paddingTop: "6px",
// 				}}
// 			>
// 				{validate}
// 			</Typography>
// 		</div>
// 	);
// };

// export default FloatingLabelInput;

import React, {useState, useEffect} from "react";
import "./TextInput.css";
import {Typography} from "@mui/material";
import {VscEye, VscEyeClosed} from "react-icons/vsc";

const FloatingLabelInput = ({
	handleChange,
	value,
	Placeholder,
	name,
	validate,
}) => {
	const [isFocused, setIsFocused] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	// Track the scroll position
	useEffect(() => {
		const handleWindowScroll = () => {
			window.previousScrollY = window.scrollY;
		};
		window.addEventListener("scroll", handleWindowScroll);

		// Clean up
		return () => {
			window.removeEventListener("scroll", handleWindowScroll);
		};
	}, []);

	const handleFocus = () => {
		// Save the scroll position when the input is focused
		if (!window.previousScrollY) {
			window.previousScrollY = window.scrollY;
		}
		setIsFocused(true);
	};

	const handleBlur = () => {
		setIsFocused(value !== "");
		// Scroll back to the initial position after the keyboard is closed
		if (window.previousScrollY !== undefined) {
			window.scrollTo(0, window.previousScrollY);
		}
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	return (
		<div className="floating-label-input" style={{position: "relative"}}>
			{/* Password Visibility Toggle Button */}
			<button
				type="button"
				className="showPassword"
				onClick={togglePasswordVisibility}
				style={{
					background: "none",
					border: "none",
					cursor: "pointer",
					position: "absolute",
					right: "10px", // Adjust based on your input styling
					top: "50%", // Adjust based on your input styling
					transform: "translateY(-50%)",
					padding: "0",
				}}
			>
				{showPassword ? <VscEyeClosed size={24} /> : <VscEye size={24} />}
			</button>

			{/* Floating Label */}
			<label className={isFocused || value ? "label-focused" : ""}>
				{Placeholder}
			</label>

			{/* Password Input */}
			<input
				type={showPassword ? "text" : "password"} // Toggle between text and password
				name={name}
				value={value}
				onFocus={handleFocus}
				onBlur={handleBlur}
				onChange={handleChange}
				autoComplete="off" // Disable autofill/autocomplete
			/>

			{/* Validation/Error Message */}
			<Typography
				sx={{
					color: "red",
					fontSize: "12px",
					paddingLeft: "12px",
					paddingTop: "6px",
				}}
			>
				{validate}
			</Typography>
		</div>
	);
};

export default FloatingLabelInput;
