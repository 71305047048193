import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const AuthCheck = ({element}) => {
	const navigate = useNavigate();
	const [isAuthenticated, setIsAuthenticated] = useState(null); // Start with null for loading state

	useEffect(() => {
		const token = localStorage.getItem("token"); // Retrieve the token from localStorage

		if (token) {
			setIsAuthenticated(true); // Set authenticated state to true if token is found
		} else {
			setIsAuthenticated(false); // Set authenticated state to false if no token is found
			navigate("/"); // Redirect to the root path (e.g., login page) if no token is found
		}
	}, [navigate]);

	// Return null or a loading indicator while authentication status is being checked
	if (isAuthenticated === null) {
		return null; // Alternatively, you could return a loading spinner here
	}

	return isAuthenticated ? element : null; // Render the element only if authenticated
};

export default AuthCheck;
