import * as React from "react";
const SVGComponent = (props) => (
	<svg
		width={20}
		height={18}
		viewBox="0 0 20 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M19.7101 6.54L17.3101 1.72C16.8401 0.77 15.5901 0 14.5301 0H5.4701C4.4101 0 3.1601 0.77 2.6901 1.72L0.290092 6.54C-0.289908 7.7 0.0300944 9.35 0.980094 10.22L7.9101 16.51C9.0601 17.55 10.9301 17.55 12.0801 16.51L19.0101 10.22C19.9701 9.35 20.2901 7.69 19.7101 6.54ZM14.0001 5.89H10.7501V11.14C10.7501 11.55 10.4101 11.89 10.0001 11.89C9.5901 11.89 9.2501 11.55 9.2501 11.14V5.89H6.0001C5.5901 5.89 5.2501 5.55 5.2501 5.14C5.2501 4.73 5.5901 4.39 6.0001 4.39H14.0001C14.4101 4.39 14.7501 4.73 14.7501 5.14C14.7501 5.55 14.4101 5.89 14.0001 5.89Z"
			fill="#3A002B"
		/>
	</svg>
);
export default SVGComponent;
