// import React, {useState} from "react";
// import "./TextInput.css";
// import {Typography} from "@mui/material";

// const FloatingLabelInput = ({
// 	handleChange,
// 	value,
// 	Placeholder,
// 	name,
// 	type = "text", // Default type to "text" for fallback
// 	validate,
// 	currency,
// }) => {
// 	const [isFocused, setIsFocused] = useState(false);

// 	const handleFocus = () => setIsFocused(true);
// 	const handleBlur = () => setIsFocused(value !== "");

// 	// Handle change event to allow only numeric input
// 	const handleNumericChange = (e) => {
// 		const {value} = e.target;
// 		if (/^\d*\.?\d*$/.test(value)) {
// 			// Regular expression to allow only digits and decimal point
// 			handleChange(e); // Call the provided handleChange function if the input is valid
// 		}
// 	};

// 	return (
// 		<div className="floating-label-input">
// 			<label className={isFocused || value ? "label-focused" : ""}>
// 				{Placeholder}
// 			</label>
// 			<input
// 				type={type === "number" ? "number" : type} // Ensure type is "number" if specified
// 				name={name}
// 				value={value}
// 				onFocus={handleFocus}
// 				onBlur={handleBlur}
// 				onChange={handleNumericChange}
// 				autoComplete="off" // Disable autofill/autocomplete
// 			/>
// 			<Typography
// 				sx={{
// 					color: "red",
// 					fontSize: "12px",
// 					paddingLeft: "12px",
// 					paddingTop: "6px",
// 				}}
// 			>
// 				{validate}
// 			</Typography>
// 			<Typography
// 				sx={{
// 					position: "absolute",
// 					right: 24,
// 					top: "50%",
// 					transform: "translateY(-50%)",
// 					fontSize: "12px",
// 				}}
// 			>
// 				{currency}
// 			</Typography>
// 		</div>
// 	);
// };

// export default FloatingLabelInput;

import React, {useState, useEffect} from "react";
import {Typography} from "@mui/material";
import "./TextInput.css";

const FloatingLabelInput = ({
	handleChange,
	value,
	Placeholder,
	name,
	type = "text", // Default type to "text"
	validate,
	currency,
}) => {
	const [isFocused, setIsFocused] = useState(false);

	// Track the scroll position
	useEffect(() => {
		const handleWindowScroll = () => {
			window.previousScrollY = window.scrollY;
		};
		window.addEventListener("scroll", handleWindowScroll);

		// Clean up
		return () => {
			window.removeEventListener("scroll", handleWindowScroll);
		};
	}, []);

	const handleFocus = () => {
		// Save the scroll position when the input is focused
		if (!window.previousScrollY) {
			window.previousScrollY = window.scrollY;
		}
		setIsFocused(true);
	};

	const handleBlur = () => {
		setIsFocused(value !== "");
		// Scroll back to the initial position after the keyboard is closed
		if (window.previousScrollY !== undefined) {
			window.scrollTo(0, window.previousScrollY);
		}
	};

	// Handle change event to allow only numeric input
	const handleNumericChange = (e) => {
		const {value} = e.target;
		// Ensure only digits and decimal points are allowed
		if (/^\d*\.?\d*$/.test(value)) {
			handleChange(e); // Call the provided handleChange function
		}
	};

	return (
		<div className="floating-label-input">
			<label className={isFocused || value ? "label-focused" : ""}>
				{Placeholder}
			</label>
			<input
				type={type === "number" ? "number" : type} // Ensure type is "number" if specified
				name={name}
				value={value}
				onFocus={handleFocus}
				onBlur={handleBlur}
				onChange={handleNumericChange}
				autoComplete="off" // Disable autofill/autocomplete
			/>
			<Typography
				sx={{
					color: "red",
					fontSize: "12px",
					paddingLeft: "12px",
					paddingTop: "6px",
				}}
			>
				{validate}
			</Typography>
			<Typography
				sx={{
					position: "absolute",
					right: 24,
					top: "50%",
					transform: "translateY(-50%)",
					fontSize: "12px",
				}}
			>
				{currency}
			</Typography>
		</div>
	);
};

export default FloatingLabelInput;
