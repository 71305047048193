const Colors = {
	PrimaryDark: "#3A002B",
	Primary: "#4C0039",
	PrimaryLight: "#760059",
	Secondary: "#394B00",
	GrayLight: "#888888",
	Black: "#000000",
	White: "#ffffff",
};

export default Colors;
