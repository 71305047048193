import React from "react";
import {Box, Typography, Dialog, useMediaQuery} from "@mui/material";
import {FaCheck} from "react-icons/fa";
import {IoClose} from "react-icons/io5";
import {Button} from "./../index";
import Colors from "../../layout/utils/Colors";

export default function Alert({
	openAlert,
	isSuccess,
	alertTitle,
	alertMessage,
	handleClick,
	buttonText,
}) {
	const isSmallScreen = useMediaQuery("(max-width: 600px)");
	const styles = AlertStyles({isSuccess});

	return (
		<Box sx={styles.alertWrapper}>
			<Dialog open={openAlert} sx={styles.dialog}>
				<Box sx={styles.alert}>
					<Box sx={styles.alertIcon}>
						{isSuccess ? (
							<FaCheck color="#fff" size={24} />
						) : (
							<IoClose color="#fff" size={24} />
						)}
					</Box>
					<Typography sx={styles.alertTitle}>{alertTitle}</Typography>
					<Box sx={styles.alertMessageWrapper}>
						<Typography sx={styles.alertMessage}>{alertMessage}</Typography>
					</Box>
					<Button
						name={buttonText}
						type="submit"
						width="100%"
						height="50px"
						color="#fff"
						fontSize={isSmallScreen ? 14 : 16}
						colorFocus="#fff"
						backgroundColor={Colors.Secondary}
						backgroundColorfocus={Colors.Secondary}
						border={`1px solid ${Colors.Secondary}`}
						borderRadius={4}
						margin="24px"
						marginTop={0}
						fontFamily="'Helvetica Neue', Arial, sans-serif"
						fontWeight="bold"
						onClick={handleClick}
					/>
				</Box>
			</Dialog>
		</Box>
	);
}

const AlertStyles = ({isSuccess}) => ({
	alertWrapper: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	alert: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		gap: "16px",
		paddingTop: "24px",
		paddingBottom: "24px", // Ensure padding is consistent
		height: "100%", // Ensure the height is defined
		boxSizing: "border-box", // To include padding in height calculation
	},
	dialog: {
		boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
		backdropFilter: "blur(1px)",
		WebkitBackdropFilter: "blur(1px)",
		backgroundColor: "rgba(255, 255, 255, 0)",
		"& .MuiPaper-root": {
			background: "#fff",
			borderRadius: "32px",
			width: "500px",
			padding: "16px",
			paddingX: "24px",
			boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
		},
		"& .MuiBackdrop-root": {
			backgroundColor: "rgba(0, 0, 0, 0.5)", // Darker background with 50% opacity
		},
	},
	alertTitle: {
		fontSize: "24px",
		color: isSuccess ? "#37b24d" : "#f03e3e",
		fontWeight: 900,
		paddingBottom: "16px",
	},
	alertMessageWrapper: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: isSuccess ? "#ebfbee" : "#fff5f5",
		padding: "12px",
		borderRadius: "12px",
		width: "100%",
	},
	alertMessage: {
		fontSize: "18px",
		color: isSuccess ? "#37b24d" : "#f03e3e",
		fontWeight: 200,
	},
	alertIcon: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		width: "60px",
		height: "60px",
		borderRadius: 99,
		backgroundColor: isSuccess ? "#4CAF50" : "#f03e3e",
		marginBottom: "12px",
	},
});
