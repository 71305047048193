// import React, {useState} from "react";
// import "./TextInput.css";
// import {Typography} from "@mui/material";

// const FloatingLabelInput = ({
// 	handleChange,
// 	value,
// 	Placeholder,
// 	name,
// 	type,
// 	validate,
// }) => {
// 	const [isFocused, setIsFocused] = useState(false);

// 	const handleFocus = () => setIsFocused(true);
// 	const handleBlur = () => setIsFocused(value !== "");

// 	return (
// 		<div className="floating-label-input">
// 			<label className={isFocused || value ? "label-focused" : ""}>
// 				{Placeholder}
// 			</label>
// 			<input
// 				type={type}
// 				name={name}
// 				value={value}
// 				onFocus={handleFocus}
// 				onBlur={handleBlur}
// 				onChange={handleChange}
// 				autoComplete="off" // Disable autofill/autocomplete
// 			/>
// 			<Typography
// 				sx={{
// 					color: "red",
// 					fontSize: "12px",
// 					paddingLeft: "12px",
// 					paddingTop: "6px",
// 				}}
// 			>
// 				{validate}
// 			</Typography>
// 		</div>
// 	);
// };

// export default FloatingLabelInput;

import React, {useState, useEffect} from "react";
import {Typography} from "@mui/material";
import "./TextInput.css";

const FloatingLabelInput = ({
	handleChange,
	value,
	Placeholder,
	name,
	type,
	validate,
}) => {
	const [isFocused, setIsFocused] = useState(false);

	// Track the scroll position
	useEffect(() => {
		const handleWindowScroll = () => {
			window.previousScrollY = window.scrollY;
		};
		window.addEventListener("scroll", handleWindowScroll);

		// Clean up
		return () => {
			window.removeEventListener("scroll", handleWindowScroll);
		};
	}, []);

	// Save scroll position when input is focused
	const handleFocus = () => {
		if (!window.previousScrollY) {
			window.previousScrollY = window.scrollY;
		}
		setIsFocused(true);
	};

	// Scroll back when input is blurred
	const handleBlur = () => {
		setIsFocused(value !== "");
		if (window.previousScrollY !== undefined) {
			window.scrollTo(0, window.previousScrollY);
		}
	};

	return (
		<div className="floating-label-input">
			<label className={isFocused || value ? "label-focused" : ""}>
				{Placeholder}
			</label>
			<input
				type={type}
				name={name}
				value={value}
				onFocus={handleFocus}
				onBlur={handleBlur}
				onChange={handleChange}
				autoComplete="off"
			/>
			<Typography
				sx={{
					color: "red",
					fontSize: "12px",
					paddingLeft: "12px",
					paddingTop: "6px",
				}}
			>
				{validate}
			</Typography>
		</div>
	);
};

export default FloatingLabelInput;
