import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Box, useMediaQuery} from "@mui/material";
import {LuShare2} from "react-icons/lu";
import {RiShareFill} from "react-icons/ri";
import Colors from "../../layout/utils/Colors";
import {
	HomeFill,
	HomeOutline,
	Transactions,
	TransactionsFill,
	PackageFill,
	PackageOutline,
} from "./../../assets/icons/index";

export default function Tabbar() {
	const navigate = useNavigate();
	const location = useLocation();
	const isSmallScreen = useMediaQuery("(max-width: 600px)");
	const styles = TabbarStyles({isSmallScreen});

	const handleNavigate = (path) => {
		navigate(path);
	};

	// Updated condition to hide TabBar on signup and its variations
	const hideTabBar =
		location.pathname === "/" ||
		location.pathname.startsWith("/sign-up") ||
		location.pathname === "/forget-password" ||
		location.pathname.startsWith("/reset-password");

	return (
		<>
			{!hideTabBar && (
				<Box sx={styles.tabBarWrapper}>
					<Box sx={styles.tabBar}>
						<button
							style={styles.tab}
							onClick={() => handleNavigate("/dashboard")}
						>
							{location.pathname === "/dashboard" ? (
								<HomeFill width={28} height={28} />
							) : (
								<HomeOutline width={28} height={28} />
							)}
							Home
						</button>
						<button
							style={styles.tab}
							onClick={() => handleNavigate("/transactions")}
						>
							{location.pathname === "/transactions" ? (
								<Transactions width={26} height={26} />
							) : (
								<TransactionsFill width={26} height={26} />
							)}
							Transactions
						</button>
						<button
							style={styles.tab}
							onClick={() => handleNavigate("/packages")}
						>
							{location.pathname === "/packages" ? (
								<PackageFill width={30} height={30} />
							) : (
								<PackageOutline width={28} height={28} />
							)}
							Packages
						</button>
						<button
							style={styles.tab}
							onClick={() => handleNavigate("/invite-friends")}
						>
							{location.pathname === "/invite-friends" ? (
								<RiShareFill size={24} />
							) : (
								<LuShare2 size={24} />
							)}
							Invite Friends
						</button>
					</Box>
				</Box>
			)}
		</>
	);
}

const TabbarStyles = ({isSmallScreen}) => ({
	tabBarWrapper: {
		zIndex: 999,
		width: isSmallScreen ? "100%" : "600px",
		height: "60px",
		backgroundColor: Colors.White,
		position: "fixed",
		bottom: 0,
		left: 0,
		right: 0,
		marginBottom: isSmallScreen ? "6px" : "6px",
		marginLeft: "auto",
		marginRight: "auto",
		justifyContent: "center",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	tabBar: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		gap: "24px",
		padding: "6px",
		width: "90%",
		borderRadius: "24px",
		boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
	},
	tab: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		fontSize: "8px",
		color: Colors.Black,
		gap: "6px",
		backgroundColor: "transparent",
		border: "none",
		outline: "none",
		WebkitTapHighlightColor: "transparent",
		cursor: "pointer",
	},
});
