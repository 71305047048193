import * as React from "react";
const SVGComponent = (props) => (
	<svg
		width={25}
		height={25}
		viewBox="0 0 25 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M17.5 2.5H7.5C4.74 2.5 2.5 4.73 2.5 7.48V13.46V14.46C2.5 17.21 4.74 19.44 7.5 19.44H9C9.27 19.44 9.63 19.62 9.8 19.84L11.3 21.83C11.96 22.71 13.04 22.71 13.7 21.83L15.2 19.84C15.39 19.59 15.69 19.44 16 19.44H17.5C20.26 19.44 22.5 17.21 22.5 14.46V7.48C22.5 4.73 20.26 2.5 17.5 2.5ZM8.5 12.5C7.94 12.5 7.5 12.05 7.5 11.5C7.5 10.95 7.95 10.5 8.5 10.5C9.05 10.5 9.5 10.95 9.5 11.5C9.5 12.05 9.06 12.5 8.5 12.5ZM12.5 12.5C11.94 12.5 11.5 12.05 11.5 11.5C11.5 10.95 11.95 10.5 12.5 10.5C13.05 10.5 13.5 10.95 13.5 11.5C13.5 12.05 13.06 12.5 12.5 12.5ZM16.5 12.5C15.94 12.5 15.5 12.05 15.5 11.5C15.5 10.95 15.95 10.5 16.5 10.5C17.05 10.5 17.5 10.95 17.5 11.5C17.5 12.05 17.06 12.5 16.5 12.5Z"
			fill="#ffffff"
		/>
	</svg>
);
export default SVGComponent;
