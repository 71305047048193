const BASE_URL = "https://api.creditbank.world/api/v1";

// Helper function to handle API requests
const apiRequest = async (
	url,
	method,
	data = null,
	requiresAuth = true,
	token = null
) => {
	const headers = {};

	if (requiresAuth) {
		token = token || localStorage.getItem("token");
		if (token) {
			headers["Authorization"] = `Bearer ${token}`;
		} else {
			window.location.href = "/";
			throw new Error("No token found, please login first.");
		}
	}

	const options = {
		method,
		headers,
	};

	if (data) {
		if (data instanceof FormData) {
			// No need to set Content-Type for FormData, browser will handle it
			options.body = data;
		} else {
			headers["Content-Type"] = "application/json";
			options.body = JSON.stringify(data);
		}
	}

	try {
		const response = await fetch(`${BASE_URL}${url}`, options);
		const result = await response.json();

		if (!response.ok) {
			if (response.status === 401 || response.status === 403) {
				window.location.href = "/";
				throw new Error("Unauthorized access, please login again.");
			}
			throw new Error(result.message || "Something went wrong");
		}

		return result;
	} catch (error) {
		console.error("API request failed:", error);
		throw error;
	}
};

// User APIs
export const signUp = (data) =>
	apiRequest("/Users/SignUp", "POST", data, false);

export const login = async (data) => {
	const result = await apiRequest("/Users/Login", "POST", data, false);

	const token = result.data; // Assuming the token is in result.data
	if (token) {
		localStorage.setItem("token", token);
		console.log("Token received and stored:", token);
	} else {
		throw new Error("Failed to retrieve the token from the login response.");
	}

	return result;
};

export const getValetBalance = (token) =>
	apiRequest("/Users/GetValet", "GET", null, true, token);

// Credit APIs
export const createCredit = (data) =>
	apiRequest("/Credit/Create", "POST", data);

export const updateCreditStatus = (id, data) =>
	apiRequest(`/Credit/UpdateStatus/${id}`, "PUT", data);

// Deposit APIs
export const createDeposit = (data) =>
	apiRequest("/Deposit/Create", "POST", data);

export const updateDepositStatus = (id, data) =>
	apiRequest(`/Deposit/UpdateStatus/${id}`, "PUT", data);

// Pickup APIs
export const createPickup = (data) =>
	apiRequest("/Pickup/Create", "POST", data);

export const updatePickupStatus = (id, data) =>
	apiRequest(`/Pickup/UpdateStatus/${id}`, "PUT", data);

// Valet APIs
export const getValetLink = () => apiRequest("/Valet/GetValetLink", "GET");

export const changeValet = (id, data) =>
	apiRequest(`/Valet/ChangeValet/${id}`, "PUT", data);

export const getTransactions = (token) =>
	apiRequest("/Transaction/GetTransactions", "GET", null, true, token);

export const getPackages = (token) =>
	apiRequest("/Post/GetPost", "GET", null, true, token);

// Invitation APIs
export const getInvitationCode = (token) =>
	apiRequest("/Users/GetInvitationCode", "GET", null, true, token);

export const getInvitationCount = (token) =>
	apiRequest("/Users/GetInvitationCount", "GET", null, true, token);

//Forget Password

export const forgetPassword = (data) =>
	apiRequest("/Users/SendEmailResetPassword", "POST", data, false);

export const resetPassword = (data) =>
	apiRequest("/Users/ResetPassword", "POST", data, false);

export const getEmailById = (id) =>
	apiRequest(`/Users/GetEmail?id=${id}`, "GET", null, false);

export const resetUserPassword = (data) =>
	apiRequest("/Users/ResetPassword", "POST", data, false);
