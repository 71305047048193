import React from "react";
import {Button} from "@mui/material";

export default function ButtonSimple({
	name,
	onClick,
	type,
	width,
	height,
	color,
	colorFocus,
	backgroundColor,
	backgroundColorfocus,
	border,
	disabled,
	fontSize,
	fontWeight,
	fontFamily,
	borderRadius,
	marginTop,
}) {
	return (
		<Button
			sx={{
				marginTop: marginTop,
				height: height,
				width: width,
				textAlign: "center",
				fontFamily: fontFamily,
				borderRadius: borderRadius,
				border: border,
				color: color,
				backgroundColor: backgroundColor,
				fontSize: fontSize,
				fontWeight: fontWeight,
				textTransform: "none", // Add this line to prevent capitalization
				"&:hover": {
					backgroundColor: backgroundColorfocus,
					color: colorFocus,
				},
				"&:focus": {
					outline: "none",
				},
				"&:active": {
					backgroundColor: backgroundColorfocus,
					color: colorFocus,
				},
			}}
			onClick={onClick}
			disabled={disabled}
			type={type}
		>
			{name}
		</Button>
	);
}
