import * as React from "react";
const SVGComponent = (props) => (
	<svg
		width={25}
		height={24}
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M21.565 10.87L20.045 9.35001C19.785 9.09 19.575 8.58001 19.575 8.22001V6.06C19.575 5.18 18.855 4.46 17.975 4.46H15.825C15.465 4.46 14.955 4.25 14.695 3.99L13.175 2.47C12.555 1.85 11.535 1.85 10.915 2.47L9.37504 3.99C9.12504 4.25 8.61504 4.46 8.24504 4.46H6.09504C5.21504 4.46 4.49504 5.18 4.49504 6.06V8.21C4.49504 8.57 4.28504 9.08 4.02504 9.34L2.50504 10.86C1.88504 11.48 1.88504 12.5 2.50504 13.12L4.02504 14.64C4.28504 14.9 4.49504 15.41 4.49504 15.77V17.92C4.49504 18.8 5.21504 19.52 6.09504 19.52H8.24504C8.60504 19.52 9.11504 19.73 9.37504 19.99L10.895 21.51C11.515 22.13 12.535 22.13 13.155 21.51L14.675 19.99C14.935 19.73 15.445 19.52 15.805 19.52H17.955C18.835 19.52 19.555 18.8 19.555 17.92V15.77C19.555 15.41 19.765 14.9 20.025 14.64L21.545 13.12C22.195 12.51 22.195 11.49 21.565 10.87ZM8.03504 9C8.03504 8.45 8.48504 8 9.03504 8C9.58504 8 10.035 8.45 10.035 9C10.035 9.55 9.59504 10 9.03504 10C8.48504 10 8.03504 9.55 8.03504 9ZM9.56504 15.53C9.41504 15.68 9.22504 15.75 9.03504 15.75C8.84504 15.75 8.65504 15.68 8.50504 15.53C8.21504 15.24 8.21504 14.76 8.50504 14.47L14.505 8.47001C14.795 8.18001 15.275 8.18001 15.565 8.47001C15.855 8.76 15.855 9.24 15.565 9.53L9.56504 15.53ZM15.035 16C14.475 16 14.025 15.55 14.025 15C14.025 14.45 14.475 14 15.025 14C15.575 14 16.025 14.45 16.025 15C16.025 15.55 15.585 16 15.035 16Z"
			fill="#171717"
		/>
	</svg>
);
export default SVGComponent;
