import * as React from "react";
const SVGComponent = (props) => (
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M15.609 0H6.391C2.387 0 0 2.387 0 6.391V15.598C0 19.613 2.387 22 6.391 22H15.598C19.602 22 21.989 19.613 21.989 15.609V6.391C22 2.387 19.613 0 15.609 0ZM17.435 13.31C17.391 13.409 17.336 13.497 17.259 13.574L13.915 16.918C13.75 17.083 13.541 17.16 13.332 17.16C13.123 17.16 12.914 17.083 12.749 16.918C12.43 16.599 12.43 16.071 12.749 15.752L14.685 13.816H5.335C4.884 13.816 4.51 13.442 4.51 12.991C4.51 12.54 4.884 12.166 5.335 12.166H16.676C16.786 12.166 16.885 12.188 16.995 12.232C17.193 12.32 17.358 12.474 17.446 12.683C17.512 12.881 17.512 13.112 17.435 13.31ZM16.665 9.823H5.335C5.225 9.823 5.126 9.801 5.016 9.757C4.818 9.669 4.653 9.515 4.565 9.306C4.477 9.108 4.477 8.877 4.565 8.679C4.609 8.58 4.664 8.492 4.741 8.415L8.085 5.071C8.404 4.752 8.932 4.752 9.251 5.071C9.57 5.39 9.57 5.918 9.251 6.237L7.326 8.173H16.676C17.127 8.173 17.501 8.547 17.501 8.998C17.501 9.449 17.127 9.823 16.665 9.823Z"
			fill="#171717"
		/>
	</svg>
);
export default SVGComponent;
