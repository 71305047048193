import React, {useState, useEffect} from "react";
import {
	Box,
	Typography,
	useMediaQuery,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button as MuiButton,
} from "@mui/material";
import {Logout} from "../../assets/icons/index";
import {LuShare2} from "react-icons/lu";
import {getInvitationCode} from "./../../api/api";
import {useNavigate} from "react-router-dom";
import {
	FacebookShareButton,
	TwitterShareButton,
	EmailShareButton,
	TelegramShareButton,
	WhatsappShareButton,
	FacebookIcon,
	TwitterIcon,
	TelegramIcon,
	WhatsappIcon,
} from "react-share";
import {Email, Message} from "./../../assets/icons/index";

export default function Header({page}) {
	const isSmallScreen = useMediaQuery("(max-width: 600px)");
	const styles = HeaderStyles({isSmallScreen});
	const [invitationUrl, setInvitationUrl] = useState("");
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchInvitationUrl = async () => {
			try {
				const {data, isSuccess} = await getInvitationCode();
				if (isSuccess) {
					setInvitationUrl(data || "");
				} else {
					console.error("Failed to fetch invitation URL");
				}
			} catch (error) {
				console.error("API call failed", error);
			}
		};

		fetchInvitationUrl();
	}, []);

	const handleShare = () => {
		if (invitationUrl) {
			setIsDialogOpen(true);
		} else {
		}
	};

	const handleLogout = () => {
		localStorage.removeItem("token");
		navigate("/");
	};

	const handleCloseDialog = () => {
		setIsDialogOpen(false);
	};

	return (
		<Box sx={styles.headerWrapper}>
			<button style={styles.button} onClick={handleLogout}>
				<Logout width={30} height={30} />
			</button>
			<Typography sx={{fontSize: "16px", fontWeight: 600}}>{page}</Typography>
			<button style={styles.button} onClick={handleShare}>
				<LuShare2 size={24} />
			</button>

			<Dialog
				open={isDialogOpen}
				onClose={handleCloseDialog}
				sx={styles.dialog}
			>
				<DialogTitle>Let’s Invite Your Friends!</DialogTitle>
				<DialogContent>
					{invitationUrl && (
						<Box sx={styles.shareButtonsContainer}>
							<Box sx={styles.iconContainer}>
								<FacebookShareButton
									url={invitationUrl}
									quote={"Join me using this link"}
								>
									<FacebookIcon size={42} round />
								</FacebookShareButton>
								<Typography sx={styles.applications}>Facebook</Typography>
							</Box>
							<Box sx={styles.iconContainer}>
								<TwitterShareButton
									url={invitationUrl}
									title={"Join me using this link"}
								>
									<TwitterIcon size={42} round />
								</TwitterShareButton>
								<Typography sx={styles.applications}>Twitter</Typography>
							</Box>
							<Box sx={styles.iconContainer}>
								<EmailShareButton
									url={invitationUrl}
									subject={"Invitation"}
									body={"Join me using this link"}
									style={{paddingBottom: "6px"}}
								>
									<Box
										sx={{
											width: "42px",
											height: "42px",
											borderRadius: 99,
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "center",
											backgroundColor: "#ea4335",
										}}
									>
										<Email />
									</Box>
								</EmailShareButton>
								<Typography sx={styles.applications}>Email</Typography>
							</Box>
							<Box sx={styles.iconContainer}>
								<TelegramShareButton
									url={invitationUrl}
									title={"Join me using this link"}
								>
									<TelegramIcon size={42} round />
								</TelegramShareButton>
								<Typography sx={styles.applications}>Telegram</Typography>
							</Box>
							<Box sx={styles.iconContainer}>
								<WhatsappShareButton
									url={invitationUrl}
									title={"Join me using this link"}
								>
									<WhatsappIcon size={42} round />
								</WhatsappShareButton>
								<Typography sx={styles.applications}>WhatsApp</Typography>
							</Box>
							{/* SMS and Email with icons */}
							<Box sx={styles.iconContainer}>
								<a
									href={`sms:?body=Join me using this link: ${invitationUrl}`}
									style={{...styles.customShareButton, paddingBottom: "6px"}}
								>
									<Box
										sx={{
											width: "42px",
											height: "42px",
											borderRadius: 99,
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "center",
											backgroundColor: "#1972e8",
										}}
									>
										<Message />
									</Box>
								</a>
								<Typography sx={styles.applications}>SMS</Typography>
							</Box>
							{/* Add more custom share links here */}
						</Box>
					)}
				</DialogContent>
				<DialogActions>
					<MuiButton onClick={handleCloseDialog}>Close</MuiButton>
				</DialogActions>
			</Dialog>
		</Box>
	);
}

const HeaderStyles = ({isSmallScreen}) => ({
	headerWrapper: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		width: "100%",
		paddingY: isSmallScreen ? "0px" : "12px",
		height: isSmallScreen ? "60px" : "auto",
	},
	button: {
		width: "45px",
		height: "45px",
		backgroundColor: "transparent",
		borderRadius: "16px",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		border: "none",
		outline: "none",
		marginBottom: "12px",
		marginTop: "16px",
		WebkitTapHighlightColor: "transparent",
		cursor: "pointer",
	},
	customShareButton: {
		display: "inline-block",
		textDecoration: "none",
		fontSize: "24px",
		color: "#000",
		textAlign: "center",
	},
	shareButtonsContainer: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "space-around",
		gap: "20px",
		marginTop: "20px",
	},
	iconContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	applications: {
		fontSize: "12px",
		fontWeight: 400,
		fontFamily:
			"-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
	},
	dialog: {
		boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
		backdropFilter: "blur(1px)",
		WebkitBackdropFilter: "blur(1px)",
		backgroundColor: "rgba(255, 255, 255, 0)",
		"& .MuiPaper-root": {
			background: "#fff",
			borderRadius: "32px",
			width: "500px",
			height: "380px",
			padding: "20px",
			boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
		},
		"& .MuiBackdrop-root": {
			backgroundColor: "transparent",
		},
	},
});
