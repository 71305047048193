import React, {Suspense} from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {Box, CircularProgress} from "@mui/material";
import {TabBar} from "./components/index";
import AuthCheck from "./layout/auth/AuthCheck";

const Signup = React.lazy(() => import("./layout/pages/SignupPage/SignupPage"));
const Signin = React.lazy(() => import("./layout/pages/SigninPage/SigninPage"));
const Dashboard = React.lazy(() =>
	import("./layout/pages/DashboardPage/DashboardPage")
);
const TransactionPage = React.lazy(() =>
	import("./layout/pages/TransactionPage/TransactionPage")
);
const PackagesPage = React.lazy(() =>
	import("./layout/pages/PackagesPage/PackagesPage")
);
const InviteFriendsPage = React.lazy(() =>
	import("./layout/pages/InviteFriendsPage/InviteFriendsPage")
);

const ResetPasswordPage = React.lazy(() =>
	import("./layout/pages/ResetPasswordPage/ResetPasswordPage")
);

const ForgetPasswordPage = React.lazy(() =>
	import("./layout/pages/ForgetPasswordPage/ForgetPasswordPage")
);

const NotFound = React.lazy(() =>
	import("./layout/pages/NotFoundPage/NotFoundPage")
);

function App() {
	return (
		<Router>
			<Box>
				<Suspense
					fallback={
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							py={4}
						>
							<CircularProgress />
						</Box>
					}
				>
					<TabBar />
					<Routes>
						<Route path="/" element={<Signin />} />
						<Route path="/sign-up" element={<Signup />} />
						<Route path="/sign-up/:invitationCode" element={<Signup />} />
						{/* Add this line */}
						<Route
							path="/dashboard"
							element={<AuthCheck element={<Dashboard />} />}
						/>
						<Route
							path="/transactions"
							element={<AuthCheck element={<TransactionPage />} />}
						/>
						<Route
							path="/packages"
							element={<AuthCheck element={<PackagesPage />} />}
						/>
						<Route
							path="/invite-friends"
							element={<AuthCheck element={<InviteFriendsPage />} />}
						/>
						<Route path="/forget-password" element={<ForgetPasswordPage />} />
						<Route
							path="/reset-password/:resetCode"
							element={<ResetPasswordPage />}
						/>
						<Route path="*" element={<NotFound />} />
					</Routes>
				</Suspense>
			</Box>
		</Router>
	);
}

export default App;
