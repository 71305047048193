import {createTheme} from "@mui/material/styles";

const muiTheme = (mode) =>
	createTheme({
		palette: {
			mode,
			primary: {
				main: "#ac2023",
			},
			secondary: {
				main: "#f47a20",
			},
			background: {
				default: mode === "light" ? "#ffffff" : "#121212",
			},
		},
		components: {
			MuiAppBar: {
				styleOverrides: {
					root: {
						height: "65px",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: mode === "light" ? "#fff" : "#1A1A1A",
						padding: 5,
						boxShadow:
							"rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
					},
				},
			},
			MuiToolbar: {
				styleOverrides: {
					root: {
						display: "flex",
						gap: "10px",
					},
				},
			},
			MuiBadge: {
				styleOverrides: {
					root: {
						"& span": {
							fontFamily: "IRANYekanXFaNum-light",
							color: "#fff",
						},
					},
				},
			},

			MuiSelect: {
				styleOverrides: {
					root: {
						backgroundColor: mode === "light" ? "#fff" : "#383838",
						color: mode === "light" ? "#495057" : "#fff",
						fontSize: 12,
						borderRadius: 10,
						height: "40px",
						fontFamily: "iranSans",
						"&:hover": {
							backgroundColor: mode === "light" ? " #f7ffff" : "#383838",
						},
						"&:hover .MuiOutlinedInput-notchedOutline": {
							borderColor: mode === "light" ? "#339af0" : "#339af0",
							opacity: 0.5,
						},
						"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
							borderColor: mode === "light" ? "#228be6" : "#339af0",
							borderWidth: 2,
							opacity: 0.5,
						},
						"&:focus": {
							outline: "none",
							boxShadow: `0 0 0 0.2rem ${
								mode === "light"
									? "rgba(255, 255, 255, 0.5)"
									: "rgba(172, 32, 35, 0.5)"
							}`,
							borderColor: "transparent",
						},
					},
					icon: {
						color: mode === "light" ? "#4c6ef5" : "#e7f5ff",
					},
				},
			},

			MuiInputLabel: {
				styleOverrides: {
					root: {
						fontSize: 12,
						fontFamily: "iranSans",
						color: mode === "light" ? "#333" : "#ddd",

						marginTop: -5,
						"&.MuiInputLabel-shrink": {
							color: "#fff",
							backgroundColor: mode === "light" ? "#228be6" : "#228be6",
							paddingLeft: 10,
							paddingRight: 10,
							marginTop: 0,
							borderRadius: 5,
						},
					},
				},
			},

			MuiMenuItem: {
				styleOverrides: {
					root: {
						fontFamily: "iranSans",
						color: mode === "light" ? "#495057" : "#fff",
						borderBottom:
							mode === "light" ? "1px solid #e7f5ff" : "1px solid #495057",
						fontSize: 12,
						marginTop: -5,
						"&:hover": {
							color: mode === "light" ? "#1c7ed6" : "#fff",
							backgroundColor: mode === "light" ? "#e7f5ff" : "#495057",
						},
					},
				},
			},
			MuiMenu: {
				styleOverrides: {
					paper: {
						borderRadius: "8px",
						marginTop: "5px",
						backgroundColor: mode === "light" ? "#fff" : "#212529",
						boxShadow:
							"rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
					},
				},
			},

			MuiAutocomplete: {
				styleOverrides: {
					root: {
						width: "100% !important",
						height: "40px",
						backgroundColor: "transparent",
						borderRadius: 10,
						"& .MuiOutlinedInput-notchedOutline": {
							outline: "none",
							border: "none",
							backgroundColor: "transparent",
							width: "100% !important",
							padding: 0,
						},
						"& .MuiOutlinedInput-root": {
							backgroundColor: "transparent !important",
							width: "100% !important",
							padding: 0,
						},
					},
					paper: {
						borderRadius: "8px",
						marginTop: "5px",
						backgroundColor: mode === "light" ? "#fff" : "#383838",
						boxShadow:
							"rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
						fontSize: 12,
						fontFamily: "iranSans",
					},
					option: {
						borderBottom:
							mode === "light"
								? "1px solid #e7f5ff !important"
								: "1px solid #495057 !important",
						"&:hover": {
							color: mode === "light" ? "#1c7ed6" : "#fff",
							backgroundColor: "transparent",
						},
					},
					inputRoot: {
						height: "40px",
						color: mode === "light" ? "#495057" : "#fff",
						fontSize: 12,
						fontFamily: "iranSans",
						borderRadius: 10,
						"& .MuiInputBase-input": {
							marginTop: -5,
						},
						"&.MuiInputLabel-shrink": {
							color: "#fff",
							backgroundColor: "transparent",
							paddingLeft: 10,
							paddingRight: 10,
							marginTop: 0,
							borderRadius: 5,
						},
						"&:hover": {
							backgroundColor: mode === "light" ? " #f7ffff" : "#383838",
						},
						"&:hover .MuiOutlinedInput-notchedOutline": {
							backgroundColor: "transparent",
							opacity: 0.5,
						},
						"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
							backgroundColor: "transparent",
							borderWidth: 2,
							opacity: 0.5,
						},
						"&:focus": {
							outline: "none",
							boxShadow: `0 0 0 0.2rem ${
								mode === "light"
									? "rgba(255, 255, 255, 0.5)"
									: "rgba(172, 32, 35, 0.5)"
							}`,
							borderColor: "transparent",
						},
						"& .MuiSvgIcon-root": {
							color: mode === "light" ? "#e64980" : "#e7f5ff",
						},
					},
					clearIndicator: {
						color: mode === "light" ? "#e64980" : "#a61e4d",
					},
					endAdornment: {
						color: mode === "light" ? "#e64980" : "#e7f5ff",
					},
				},
			},
			// MuiTypography: {
			// 	styleOverrides: {
			// 		body1: {
			// 			color: mode === "light" ? "#343a40" : "#ddd",
			// 			fontFamily: "IRANYekanXFaNum-Regular",
			// 			fontSize: 12.8,
			// 		},
			// 		body2: {
			// 			color: "red",
			// 			fontFamily: "iranSans",
			// 		},
			// 		caption: {
			// 			fontFamily: "IRANSans",
			// 			overflow: "hidden",
			// 			whiteSpace: "pre-wrap",
			// 		},
			// 		subtitle1: {
			// 			fontFamily: "IRANYekanXFaNum-bold",
			// 			fontSize: 14,
			// 		},
			// 		subtitle2: {
			// 			fontFamily: "IRANSans",
			// 			fontSize: 10,
			// 		},
			// 		h1: {
			// 			color: mode === "light" ? "#495057" : "#fcc419",
			// 			fontFamily: "IRANYekanXFaNum-ExtraBold",
			// 			fontSize: 14,
			// 		},
			// 		h2: {
			// 			color: "red",
			// 			fontFamily: "iranSans",
			// 		},
			// 		h3: {
			// 			color: "red",
			// 			fontFamily: "iranSans",
			// 		},
			// 		h4: {
			// 			color: "red",
			// 			fontFamily: "iranSans",
			// 		},
			// 		h5: {
			// 			color: "red",
			// 			fontFamily: "iranSans",
			// 		},
			// 		h6: {
			// 			color: "red",
			// 			fontFamily: "iranSans",
			// 		},
			// 	},
			// },

			MuiTextField: {
				styleOverrides: {
					root: {
						width: "100%",
						"& .MuiOutlinedInput-root": {
							height: "40px",
							fontFamily: "iranSans",
							borderRadius: 10,
							backgroundColor: mode === "light" ? " #ffffff" : "#383838",
							fontSize: 12,
							"&:hover fieldset": {
								borderColor:
									mode === "light"
										? "#339af0 !important"
										: "#339af0 !important",
								backgroundColor: mode === "light" ? " #f7ffff" : "#383838",
								opacity: 0.5,
							},
							"&.Mui-focused fieldset": {
								borderColor: mode === "light" ? "#339af0" : "#339af0",
								opacity: 0.5,
							},
						},
					},
				},
			},

			MuiList: {
				styleOverrides: {
					root: {
						width: "100%",
						"& .MuiListItem-padding": {
							paddingTop: 0,
							paddingBottom: 0,
						},
						"& .MuiListItemText-primary": {
							fontSize: 12,
						},
						"& .MuiListItemText-secondary": {
							fontSize: 10,
						},
						"& .MuiAvatar-circular": {
							width: 35,
							height: 35,
							marginLeft: 8,
						},
					},
				},
			},

			MuiAlert: {
				styleOverrides: {
					root: {
						fontFamily: "IRANYekanXFaNum-light",
					},
				},
			},

			MuiPopover: {
				styleOverrides: {
					root: {
						marginTop: 20,
					},
					paper: {
						borderRadius: 10,
					},
				},
			},

			MuiTabs: {
				styleOverrides: {
					root: {
						padding: 0,
						margin: 0,
					},
				},
			},

			MuiTab: {
				styleOverrides: {
					root: {
						padding: 10,
						fontSize: 16,
						fontFamily: "IRANYekanXFaNum-Regular, Arial, sans-serif",
						"&.Mui-selected": {
							color: mode === "light" ? "#ac2023" : "#fab005",
						},
					},
					wrapper: {
						color: mode === "light" ? "#ac2023" : "#fab005",
					},
				},
			},

			MuiDrawer: {
				styleOverrides: {
					root: {
						"& .MuiDrawer-paper": {
							borderRadius: "10px 10px 0 0",
							backgroundColor: mode === "light" ? "#fff" : "#000",
						},
					},
				},
			},

			MuiAvatarGroup: {
				styleOverrides: {
					root: {
						"& .MuiAvatar-circular": {
							width: 35,
							height: 35,
						},
						"& .MuiAvatarGroup-avatar": {
							fontFamily: "IRANYekanXFaNum-Regular",
							fontSize: 12,
							borderColor: "transparent",
						},
					},
				},
			},
		},
	});

export default muiTheme;
